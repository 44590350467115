$main-purple:#7209B7;
.cart-dropdown {
    position: absolute;
    width: 340px;
    height: 340px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0px 0px 10px #000;
  background: rgb(7,69,115);
background: linear-gradient(49deg, rgba(7,69,115,1) 13%, rgba(75,5,122,1) 100%);
    top: 60px;
    right: 0px;
    z-index: 5;
  
    .cart-items {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      position: relative;
    }
  
   .checkout-btn {
    margin-top: auto;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-family: "poppinssemibold";
    padding: 1rem;
    background-color: #4CC9F0;
    color: #fff;
   }
   .checkout-btn:hover{
    background-color: #4ccaf09c;
    color: #45066e;
   }
   .checkout-btn:focus{
    background-color: #4ccaf0b7;
    color: #fff;
   }
  }
  .empty-cart-message {
    color: rgba(255, 255, 255, 0.897);
    text-align: center;
    font-family: "poppinssemibold";
    margin: auto;
    
  }
  .close-cart{
    position: absolute;
    top:-2%;
    right: 1.5%;
    color: #fff;
    cursor: pointer;
    
  }
  .clear-cart {
   
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-family: "poppinssemibold";
    padding: 1rem;
    margin-top: .2rem;
    background-color: #F72585;
    color: #ffffff;
   }

   .clear-cart:hover{
    background-color: #f725848f;
   }
   .clear-cart:focus{
    background-color: #f72584b6;
    color: rgba(255, 255, 255, 0.863);
   }