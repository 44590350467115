$sub-color: grey;
$main-color: black;
$main-pink:#F72585;
$main-purple:#7209B7;
$main-blue:#3F37C9;
$sub-blue:#4CC9F0;

@mixin shrinkLabel {
  top: -14px;
  font-size: 12px;
  color: #fff;
}

.form-group {
  position: relative;
  font-family: "poppinsregular";
  margin: 50px 0;

  .form-input {
    background: none;
    background-color: rgba(255, 255, 255, 0.562);
    color: #fff;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $sub-color;
    margin: 25px 0;

    &:focus {
      outline: none;
      background-color: rgba(255, 255, 255, 0.308);
    }

    &:focus ~ .form-label {
      @include shrinkLabel();
    }
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  .form-label {
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
    text-transform: capitalize;

    &.shrink {
      @include shrinkLabel();
    }
  }
}
