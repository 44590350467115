.checkout-item {
    width: 100%;
    display: flex;
    min-height: 100px;
    border-bottom: 1px solid darkgrey;
    padding: 15px 0;
    font-size: 20px;
    align-items: center;
    color: #fff;
    text-transform: capitalize;
    font-family: "poppinsregular";
    .img-container {
      width: 23%;
      padding-right: 15px;
        
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name,
    .quantity,
    .price {
      width: 23%;
      padding: 0rem .5rem ;
    }
  
    .quantity {
      display: flex;
     
      .arrow {
        cursor: pointer;
      }
      .value {
        margin:5px 10px
      }
    }
  
    .remove-button {
      padding-left: 12px;
      cursor: pointer;
    }
  }
  @media screen and (max-width:576px){
    .checkout-item{
      .quantity,.price{
        width:20vw
      }
      .name{
        font-size:.7rem;
        line-height: 1.2rem;
      }
    }
  }