
.menu-item{
    width: 30vw;
    height: 45vh;
    border: solid 1px #4CC9F0;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    .background-img{ 
        height: 100%;
        width: 100%;
    }
    .content {
        background-color: #7109b781;
        padding: .5rem 1rem;
        position: absolute;
        h2 {
            text-transform: capitalize;
            color:#4CC9F0
        }

    }
&:hover {
    .background-img{ 
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
    .content {
        background-color: #7109b7d8;
      
     
}}
    
} 
