.directory-menu {
    display:flex;
    margin-top: 2rem;
    gap:1rem;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items:center;
    justify-content: center;
    font-family: 'poppinsregular', sans-serif;
 }

 @media screen and (max-width:576px) {
    .directory-menu {
        flex-direction: column;
    }
    .menu-item{
        width: 100%;
        border: none;
        
    }
 }
 @media screen  and (max-width:1000px) and (min-width:700px){
    .menu-item{
        width: 45vw;
    }
 }