$main-pink:#F72585;
$main-purple:#7209B7;
$main-blue:#3F37C9;
$sub-blue:#4CC9F0;
.sign_in_container{
    width: 30vw;
    margin: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    h2{
        color:$sub-blue;
        font-family: "poppinssemibold";
    }
    span {
        color: rgba(255, 255, 255, 0.863);
        font-family: "poppinsregular";
        font-size: .9rem;}
    

    
}

.form-btn {
    background-color: #ffffff7e;
    font-size: 1rem;
    color: #Fff;
    font-family: "poppinssemibold";
    text-transform: uppercase;
    padding: .5rem 1.5rem;
   
    border: 1px solid transparent;
    border-radius: 2rem;
    cursor: pointer;
    transition: all .5s ease-in-out;
}
.form-btn:hover{
    background-color: #0594c0;
}
.form-btn-google {
    font-size: 1rem;
    display: flex;
    height: 100%;
    align-items: center;
    background-color: #fff;
    border: solid 1px $sub-blue;
    font-family: "poppinssemibold";
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 2rem;
    padding: .5rem 1.5rem;
    cursor: pointer;
    color: $sub-blue;
    transition: ease-in-out .3s all;
    .google-icon{
        color: $sub-blue;
        margin: 0;
        padding-right:.2rem ;
        

        font-size: 1.2rem;
    }
}
.form-btn-google:hover{
    background-color: #0594c0;
    color: #fff;
}
.btns {
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width:600px) {
    .sign_in_container{
        width: 100%;
       margin: 0;
        
    }
    .form-btn{
        font-size: .8rem;
    }
    .form-btn-google{
        font-size: .84rem;
    }
}

@media screen and (max-width:1200px) and (min-width:601px) {
    .sign_in_container{
        width: 45vw;
    }
    .form-btn{
        font-size: .7rem;
    }
    .form-btn-google{
        font-size: .7rem;
    }
}