.popup-page {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:99999;
    background-color: rgba(0, 0, 0, 0.596);
    display: flex;
    align-items: center;
    justify-content: center;

    .popup-container{
        width: 35vw;
        height: 40vh;
        background-color: rgba(255, 255, 255, 0.863);
        color:rgb(51, 51, 51);
        border-radius: .2rem;
        h2{
            text-align: center;
            font-family: "poppinssemibold";

        }
        p{
            font-family: "poppinssemibold";
            font-size: 1rem;
            
            color: rgb(51, 51, 51);
            padding: .7rem 1rem;
            line-height: 1.3rem;
        }
        .firebase {
            color: #ec8600;
            text-transform: uppercase;
        }
        button {
            width: 5
            20%;
            margin-left: 1rem;
            padding: .5rem 1rem;
            font-family: "poppinssemibold";
            font-size: 1rem;
            cursor: pointer;
            border: none;
            color: rgba(255, 255, 255, 0.925);
            background-color: #4CC9F0;
            border-radius: .7rem;
        }
        button:hover{
            background-color: #79d3ee;
        }
    }
}
@media screen and (max-width:576px) {
    .popup-page{

    
    .popup-container {
        width:80vw;
        height:50vh
    }
}
}