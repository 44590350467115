.nf-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2{
        color:#fff;
        font-family: "poppinsbold";
        font-size: 1.5rem;
    }
    span{
        color: #fff;
        font-family: "poppinsthin";
        font-size: 1.2rem;
    }
}