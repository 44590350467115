.collection-page {
    display:flex;
    flex-direction: column;
    .title {
        color:#fff;
        font-size: 1.4rem;
        font-family: "poppinssemibold";
        text-transform: uppercase;
        margin: 0 auto 30px ;
    }
    .collection-items{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
    }
}
@media screen and (max-width:576px){
    .collection-page{ 
        .collection-items{
            grid-template-columns:5fr
        }}
}

@media screen and (max-width:1000px) and (min-width:701px){
   .collection-page{ 
    .collection-items{
        grid-template-columns:5fr
    }}
}