body {
    background: rgb(7,69,115);
    background: radial-gradient(circle, rgba(7,69,115,1) 35%, rgba(75,5,122,1) 100%);
    width:100%;
     
}


.scroll-btn{
    position: fixed; 
    width: 3vw;
    left:95%;
    height: 30px;
    font-size: 5rem;
    bottom:2%;
    z-index: 1;
    cursor: pointer;
    color: #fff;
   
}
@media screen and (max-width:576px) {
    .scroll-btn{
        width: 8vw;
        height: 40px;
        left: 90%;
    }
}