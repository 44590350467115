.checkout-page {
    width: 60%;
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    align-items: center;
    margin: 3rem auto 0;
    .checkout-header {
        width: 100%;
        color: #fff;
        font-family: "poppinssemibold";
        display: flex;
        padding: .5rem 0rem;
        justify-content: space-between;
        border-bottom: 1px solid #fff ;
        text-transform: capitalize;
        .header-block {
            width: 23%;

            &.last-child {
                width: 8%;
            }
        }
    }
    .checkout-body {
        width: 100%;
        height: 100%;
        border-bottom: 1px solid #fff;
        .empty-cart-message {
            color: rgba(255, 255, 255, 0.897);
            text-align: center;
            font-family: "poppinssemibold";
            width:100;
            height: 10%;
            padding: 5rem;
            margin: auto;
          }
    }
    .checkout-total {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "poppinssemibold";
        
        color: #4CC9F0;
        span{
            color: #fff;
            margin-left: auto;
            font-size: 1.2rem;
            padding: 1rem;
        }
        button {
            margin-left:auto;
        }
        .test-info {
            h2{
                font-family: "poppinssemibold";
                font-size: .6rem;
                color: #2dcdfd;
                text-align: center;
            }
            span{
                font-family: "poppinsregular";
                text-align: center;
                font-size: 1.1rem;
                color: #fff;
            }
        }
    }
}
@media screen and (max-width:600px){
    .checkout-page {
        width:95%;
        .checkout-header{
            font-size:.9rem;
        }
      .checkout-item{
        font-size:.8rem
      }
    }
}