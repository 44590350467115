.contact-page{
    display: flex;
    width: 100%;
    height: 80vh;
    
    justify-content: center;
    align-items: center;
    
    .banner {
    height: 50%;
    width: 45%;
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    border-right: 1px solid #fff;
    
    }
    .contact-info {
        width: 50%;
        height: 50%;
        background-color: rgba(255, 255, 255, 0.267);
        h2{
            font-family: "poppinsitalic";
            text-align: center;
            color:#FFF;
        }
        p{
            color: #E1B5FF;
            text-align: center;
            font-family: "poppinsregular";
        }
        .contact-details{
            display: flex;
            flex-direction: column;
            padding: 1rem;

            .location,.phone,.mail{
                color: #fff;
                font-family: "poppinsmedium";
                font-size: 1rem;
                cursor: pointer;
                display: flex;
                align-items: center;
                margin: .5rem .5rem;
                svg{
                    margin-right: .8rem;
                    font-size: 1.2rem;
                    color: #fff;
                }
            }
        }
    }


}

@media screen and (max-width:600px) {
    .contact-page{
        flex-direction: column-reverse;
        .contact-info{
            width: 100%;
        }
       .banner{
        border: none;
        img{
            width: 100%;
        }
       }
        
    }
}

@media screen and (max-width:1200px) and (min-width:601px){
    .contact-page{
        flex-direction:column-reverse;
        .contact-info{
            width: 100%;
        }
       .banner{
        border: none;
        img{
            width: 100%;
        }}
    }
}