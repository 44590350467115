$main-pink:#F72585;
$main-purple:#7209B7;
$main-blue:#3F37C9;
$sub-blue:#07a7d8;
.collection-item {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    width: 20vw;
    height: 50vh;
    margin: 1rem;
    cursor: pointer;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.253);
    
    

.collection-img {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 88%;
}
.collection-footer {
    
    color: #fff;
    height: 12%;
    width: 100%;
    font-size: 1.1rem;
   display: flex;
   justify-content: space-between;
   text-transform: uppercase;
   .price {
    background-color: #F72585;
    color: #fff;
    border-radius: 1px 1px 5px;
    padding: .2rem .4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
   }
   .name{
    background-color: rgba(255, 255, 255, 0.295);
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: .5rem;
    font-family: "poppinssemibold";
    font-size: 1rem;
   }
}
.add_to_cart_btn{
    position: absolute;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-family: "poppinssemibold";
    padding: 1rem;
    background-color: #4CC9F0;
    color: #45066e;
    display: none;
    .mini_bag_icon{ 
        margin-left: .3rem;
    }
   }
   .add_to_cart_btn:hover {
    background-color: #F72585;
    color:#FFF
   }
   .add_to_cart_btn:focus {
    outline:#4CC9F0;
    box-shadow: 0px 0px 10px #ff9fca inset
   }
   &:hover{

    .collection-img{
        opacity: .5;
        transition: opacity .6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        
    }
        
    .add_to_cart_btn{
       
        display: flex;
       }
   }

}
@media screen and (max-width:700px) {
    .collection-item{
        width:92vw
    }
}
@media screen and (max-width:1000px) and (min-width:701px){
    .collection-item {
        width: 95vw
    }
}