$main-pink:#F72585;
$main-purple:#7209B7;
$main-blue:#3F37C9;
$sub-blue:#4CC9F0;

.sign-up{
    width:30vw;
    margin: 1rem;
    padding: 1rem;
    
    box-sizing: border-box;
    h2{
        color:#e1b5ff ;
        font-family: "poppinssemibold";
    }
    span {
        color: rgba(255, 255, 255, 0.863);
        font-family: "poppinsregular";
        font-size: .9rem;}
    
}

.form-btn2 {
    background-color: #ffffff7e;
    font-size: 1rem;
    color: #Fff;
    font-family: "poppinssemibold";
    text-transform: uppercase;
    padding: .5rem 1.5rem;
   
    border: 1px solid transparent;
    border-radius: 2rem;
    cursor: pointer;
    transition: all .5s ease-in-out;
}
.form-btn2:hover{
    background-color: #0594c0;
}

.btns {
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width:600px) {
    .sign-up{
        width: 100%;
        margin: 0;
        .form-btn2{
            font-size: .8rem;
        }
        
    }
}
@media screen and (max-width:1200px) and (min-width:601px) {
    .sign-up{
        width: 45vw;
    }
}