.navbar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding:1rem;
    font-family: "poppinssemibold";
  

    .signin-item{
        background-color: rgba(255, 255, 255, 0.514);
        border-radius: 5%;
        
        height: 50%;
    }
  
}


.navlist-container{
    display: flex   ;
    width: 100%;
    align-items: center;
    justify-content: space-between;
   
}
.navlist {
    list-style: none;
    display: flex;
    justify-content: center;
   width: 100%;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    .nav-link{
        text-decoration: none;
        font-size: 1rem;
        color: #fff;
        padding: 1.2rem;
        display: block;
        cursor: pointer;
        transition: all .5s ease-in-out;
  
    }
    .nav-link:hover {
        color:#4CC9F0
    }
   
  &:last-child{
    display: flex;
    align-items: center;
   
    
  }
  .last{
    margin-right: auto;
  }

}

.sign-btn {
    background-color: #ffffff7e;
    font-size: 1rem;
    color: #Fff;
    font-family: "poppinssemibold";
    text-transform: uppercase;
    padding: .5rem 1.2rem;
   
    border: 1px solid transparent;
    border-radius: 2rem;
    cursor: pointer;
    transition: all .5s ease-in-out;
}
.sign-btn:hover {
    background-color: transparent;
    border: 1px solid #ffffff7e ;
}
.signin-link{
    padding: 1.2rem ;
    margin: 0rem .2rem;
   
    
}
  
.cart-link {
    position: relative;
    padding: .4rem .3rem!important;
    margin: 0rem .1rem ;
    .cart-icon {
        padding: 0;
        margin: 0;
        font-size: 2rem;
    }
    .cart-counter {
        position: absolute;
        font-size: .7rem;
        left:40%;
        top:42%
    }
}
.toggle-button {
    position: absolute;
    top:1.2rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 2rem;
    height: 1.35rem;
    background-color: transparent;
    outline: none;
    color: #fff;

}
.mr-auto {
    margin-right: auto;
}

@media screen and (max-width: 576px) {
    .toggle-button {
        display: flex;
    }
    .navlist-container{
        display: none!important;
        margin-top: .5rem;
        width: 100%;
    }
    .navbar{flex-direction: column;
   align-items: flex-start;
   .last{
    margin: auto;
   }
    }
    .navlist{
        width: 100%;
        flex-direction:column ;
    }
    .navlist-container.active{
        display: flex!important;
    }
}