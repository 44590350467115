.sign-in-sign-up-page {
    display: flex;
    width: 100%;
    justify-content: center;
   
}
@media screen and (max-width:576px) {
    .sign-in-sign-up-page {
        flex-direction: column;
    }
}