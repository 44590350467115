* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'poppinsbold';
  src: url('/public/assets/fonts/poppins-bold-webfont.woff2') format('woff2'),
       url('/public/assets/fonts/poppins-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsitalic';
  src: url('/public/assets/fonts/poppins-italic-webfont.woff2') format('woff2'),
       url('/public/assets/fonts/poppins-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsmedium';
  src: url('/public/assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
       url('/public/assets/fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsregular';
  src: url('/public/assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
       url('/public/assets/fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinssemibold';
  src: url('/public/assets/fonts/poppins-semibold-webfont.woff2') format('woff2'),
       url('/public/assets/fonts/poppins-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsthin';
  src: url('/public/assets/fonts/poppins-thin-webfont.woff2') format('woff2'),
       url('/public/assets/fonts/poppins-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}