.cart-item {
    display: flex;
    padding: .5rem 0rem;
    height: 100%;
    width: 100%;
    justify-content: center;
    margin-bottom: .1rem;
    img {
        width: 35%;
        height: 70%;
    }
    .cart-item-details {
        color: #4CC9F0;
        display: flex;
        width: 65%;
        flex-direction: column;
        align-items: flex-start;
        padding: .1rem .5rem;
        .name {
            font-family: "poppinssemibold";
            text-transform: uppercase;
            padding: .2rem 0rem;
        }
        .price{
            font-family: "poppinsregular";
            color:#fff
        }
        
    }
}