$main-pink:#F72585;
$main-purple:#7209B7;
$main-blue:#3F37C9;
$sub-blue:#4CC9F0;
.collection-preview {
    color: rgb(225, 181, 255);
    
    .title {
        margin: 1rem 5rem;
    }
    .preview{
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width:700px) {
    .preview{
        flex-direction: column;
    }
}
@media screen and (max-width:1000px) and (min-width:701px){
    .preview{
        flex-direction:column;
    }
}
